import Vue from 'vue';

export default {
    find(parametros) {
        return Vue.axios.get("calculo-nomina/find", { params: parametros }).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    /*get(id) {
        return Vue.axios.get("calculo-nomina/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },*/
    update(parametros) {
        return Vue.axios.put("calculo-nomina", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    delete(id) {
        return Vue.axios.delete("calculo-nomina/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    store(parametros) {
        return Vue.axios.post("calculo-nomina", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    calcular(parametros) {
        return Vue.axios.post("calculo-nomina/calcular", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    calcularPeriodo(parametros) {
        return Vue.axios.post("calculo-nomina/calcular-periodo", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    acumular(parametros) {
        return Vue.axios.post("calculo-nomina/acomular", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    deleteAguinaldo(parametros){
        return Vue.axios.post("calculo-nomina/delete-aguinaldo", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    timbrar(parametros) {
        return Vue.axios.post("nominas-timbres", parametros).then((response) => Promise.resolve(response)).catch((error) => Promise.reject(error));
    },
    retimbrar(parametros) {
        return Vue.axios.post("nominas-timbres/reTimbra", parametros).then((response) => Promise.resolve(response)).catch((error) => Promise.reject(error));
    },
    timbraErrores(parametros) {
        return Vue.axios.post("nominas-timbres/timbraErrores", parametros).then((response) => Promise.resolve(response)).catch((error) => Promise.reject(error));
    },
    cancelar(parametros) {
        return Vue.axios.post("nominas-timbres/cancelacion", parametros).then((response) => Promise.resolve(response)).catch((error) => Promise.reject(error));
    },
    enviarCorreo(parametros) {
        return Vue.axios.post("nominas-timbres/enviar", parametros).then((response) => Promise.resolve(response)).catch((error) => Promise.reject(error));
    },
    calcularFiniquito(parametros) {
        return Vue.axios.post("calculo-nomina/calcular-finiquito", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    calcularAguinaldo(parametros) {
        return Vue.axios.post("calculo-nomina/calcular-aguinaldo", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    eliminarEmpleadoPrecalculo(parametros) {
        return Vue.axios.post("calculo-nomina/delete-detalle", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    getEmpleadosAcumuladoTimbre(parametros) {
        return Vue.axios.get("nominas-timbres/detalle", { params: parametros }).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    timbreMasivo(parametros) {
        return Vue.axios.post("nominas-timbres/masivo", parametros).then((response) => Promise.resolve(response)).catch((error) => Promise.reject(error));
    },
    acumularTimbrar(parametros) {
        return Vue.axios.post("nominas-timbres/acomular-timbrar", parametros).then((response) => Promise.resolve(response)).catch((error) => Promise.reject(error));
    },
    odessaConfirmacion(parametros){
        return Vue.axios.post("odesa/confirmacion",parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    odessaMoper(parametros){
        return Vue.axios.post("odesa/moper",parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    getEmpleadosDesacumulados(id) {
        return Vue.axios.get("calculo-nomina/desacumulados/timbrar/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },



    desacumular(parametros){
        return Vue.axios.post("calculo-nomina/desacumular", parametros).then((response) => Promise.resolve(response)).catch((error) => Promise.reject(error));
    },
    getComplementosDesacumulados(parametros) {
        return Vue.axios.get("calculo-nomina/complementos/desacumulados", { params: parametros }).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    timbraRetimbrarDesacumulados(parametros) {
        return Vue.axios.post("nominas-timbres/retimbrado-desacumulados", parametros).then((response) => Promise.resolve(response)).catch((error) => Promise.reject(error));
    },
    getEmpleadosAcumularTimbrar(id) {
        return Vue.axios.get("calculo-nomina/desacumulados/timbrar/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },

}