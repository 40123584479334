<template>
    <div id="pageTable">        
        <v-container xl fluid>
            <v-row>
                 <v-overlay :value="overlay" >
                    <div class="align-center justify-center text-center">
                        <v-progress-circular
                            indeterminate
                            :size="100"
                            :width="7"
                            color="#2DB9D1">
                            <v-img
                                src="/static/icon/favicon.ico"
                                :width="60">
                            </v-img>
                        </v-progress-circular>
                        <p class="mt-5">{{fraseOverlay}}</p>
                    </div>
                    
                </v-overlay>
                <v-col cols="12" xs="12" sm="12" md="12" class="center">
                    <div class="margen d-flex">
                        <hr class="vr"/>
                        <p class="tblName">Mis reportes</p>
                    </div>
                    <v-card class="borde-card big elevation-0">
                        <v-card-text class="pa-5" >
                            <ValidationObserver ref="form" v-slot="{ invalid, validated  }">
                                <v-row>
                                    <v-col cols="6" >
                                        <v-menu button rounded offset-y class="elevation-0 d-inline ml-2 mr-2 "> 
                                            <template v-slot:activator="{on, attrs}">
                                                <v-btn
                                                    color="#EFEFEF" 
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    right
                                                    rounded
                                                    class="btnReportes justify-space-between elevation-0 col-lg-8 "
                                                >
                                                {{reporte_dinamico_nombre}}
                                                <v-icon class="">keyboard_arrow_down</v-icon>
                                                </v-btn>
                                            </template>

                                            <v-list>
                                                <v-list-item 
                                                    v-for="(item, index) in reportesDinamicos"
                                                    :key="index"
                                                >   
                                                    <v-list-item-title  
                                                    @click="changeReporteDinamicoItem(item)"
                                                    class="cursor-pointer"
                                                    >
                                                        {{ item.nombre }}
                                                    </v-list-item-title>
                                                    <v-btn 
                                                        v-if="item.cliente_id"
                                                        depressed
                                                        icon
                                                        fab
                                                        dark
                                                        small
                                                        class="botonHover" 
                                                        @click="eliminarReporte(item.id)"
                                                    >
                                                        <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                                    </v-btn>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="6" class="d-flex justify-end align-center text-center">
                                        <div class="text-exportar">
                                            Exportar en 
                                        </div>
                                        <v-btn
                                            color="#FFFFFF" 
                                            @click="generarReporte('xlsx')"
                                            rught
                                            rounded
                                            class="btnReportes elevation-0"
                                        >
                                            <img src="/static/icon/xls.png" style="width:28px" />
                                        </v-btn>
                                        <v-btn
                                            color="#FFFFFF" 
                                            rught
                                            rounded
                                            class="btnReportes elevation-0"
                                        >
                                            <img src="/static/icon/pdf.png" style="width:28px" />
                                        </v-btn>

                                        <v-btn
                                            rounded
                                            class="btnGuardar btnReportes  elevation-0 "
                                            @click="resetValues()"
                                        >
                                            Limpiar 
                                            <v-icon class="">refresh</v-icon>
                                        </v-btn>
                                        <v-btn
                                            rounded
                                            class="btnNormal ml-3 btnReportes  elevation-0"
                                            @click="openModalSave()"
                                        >
                                            <template v-if="accion == 'create'">Guardar</template> 
                                            <template v-else>Actualizar</template> 
                                            <v-icon class="">save</v-icon>
                                        </v-btn>
                                        
                                        
                                            
                                        
                                    </v-col> 
                                    <v-col v-if="rol == 'root'" cols="12" xs="12" sm="2" md="2" class=" pb-0 ma-0">
                                        <ValidationProvider v-slot="{ errors }" name="Cliente" rules="required">
                                            <v-autocomplete
                                                outlined
                                                label="Clientes"
                                                :items="clientes"
                                                item-text="nombre"
                                                item-value="id"
                                                persistent-hint
                                                v-model="cliente_value"
                                                class="pa-0 ma-0"
                                                required 
                                                :error-messages="errors"
                                            >
                                            </v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col v-if="rol == 'root' || rol=='admin'" cols="12" xs="12" :sm="rol == 'root'?2:4" :md="rol == 'root'?2:4" class=" pb-0 ma-0">
                                        <VuetifyMultipleAutocomplete
                                            outlined
                                            :items="registrosPatronales"
                                            item_text="nombreClave"
                                            item_value="id"
                                            @dataMultiple="(data)=> registrosPatronalesSeleccionados = data"
                                            rules="required"
                                            label="Registros patronales"
                                            ref="multRegistroPatronal"
                                        />
                                    </v-col>
                                    <v-col v-if="rol == 'root' || rol=='admin'" cols="12" xs="12" sm="4" md="4" class=" pb-0 ma-0">
                                        <VuetifyMultipleAutocomplete
                                            outlined
                                            :items="empresas"
                                            item_text="nombre"
                                            @dataMultiple="(data)=> empresasSeleccionadas = data"
                                            label="Empresas"
                                            ref="multEmpresa"
                                            :loading="loadingEmpresa"
                                        />
                                    </v-col>
                                    <v-col v-if="rol == 'root' || rol=='admin' || rol=='admin-empresa'" cols="12" xs="12" sm="2" md="2" class=" pb-0 ma-0">
                                        <VuetifyMultipleAutocomplete
                                            outlined
                                            :items="sucursales"
                                            item_text="nombre"
                                            @dataMultiple="(data)=> sucursalesSeleccionadas = data"
                                            label="Sucursales"
                                            ref="multSucursal"
                                            :loading="loadingSucursales"
                                        />
                                    </v-col>
                                    <v-col v-if="rol == 'root' || rol=='admin' || rol=='admin-empresa'" cols="12" xs="12" sm="2" md="2" class=" pb-0 ma-0">
                                        <VuetifyMultipleAutocomplete
                                            outlined
                                            :items="departamentos"
                                            item_text="nombre"
                                            @dataMultiple="(data)=> departamentosSeleccionados = data"
                                            label="Departamentos"
                                            ref="multDepartamento"
                                            :loading="loadingDepartamentos"
                                        />
                                    </v-col>
                                    <v-col v-if="rol == 'root' || rol=='admin' || rol=='admin-empresa'" cols="12" xs="12" sm="4" md="4" class=" pb-0 ma-0">
                                       
                                        <VuetifyMultipleAutocomplete
                                            outlined
                                            :items="tipos_nominas"
                                            item_text="claveDescripcion"
                                            @dataMultiple="(data)=> tiposNominasSeleccionadas = data"
                                            label="Tipo de nómina"
                                            ref="multTiposNomina"
                                            :loading="loadingTiposNomina"
                                        />
                                    </v-col>
                                    <v-col v-if="rol == 'root' || rol=='admin' || rol=='admin-empresa'" cols="12" xs="12" sm="4" md="4" class=" pb-0 ma-0">
                                        <v-autocomplete
                                            outlined
                                            label="Año"
                                            :items="items_anios"
                                            item-text="nombre"
                                            item-value="id"
                                            persistent-hint
                                            v-model="anio_id"
                                            class="pa-0 ma-0"
                                        >
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col v-if="rol == 'root' || rol=='admin' || rol=='admin-empresa'" cols="12" xs="12" sm="4" md="4" class=" pb-0 ma-0">
                                        <VuetifyMultipleAutocomplete
                                            outlined
                                            :items="meses"
                                            item_text="nombre"
                                            item_value="valor"
                                            @dataMultiple="(data)=> mesesSeleccionados = data"
                                            label="Mes de acumulación"
                                            ref="multMeses"
                                        >
                                        </VuetifyMultipleAutocomplete>
                                    </v-col>
                                    <v-col v-if="rol == 'root' || rol=='admin' || rol=='admin-empresa'" cols="12" xs="12" sm="4" md="4" class=" pb-0 ma-0">
                                        <VuetifyMultipleAutocomplete
                                            outlined
                                            :items="nominas"
                                            item_text="nombre"
                                            @dataMultiple="(data)=> nominasSeleccionadas = data"
                                            label="Nóminas"
                                            ref="multNominas"
                                            :loading="loadingNominas"
                                        />
                                    </v-col>

                                    <v-col v-if="rol == 'root' || rol=='admin' || rol=='admin-empresa'" cols="12" xs="12" sm="4" md="4" class="pb-0 ma-0">
                                        <VuetifyMultipleAutocomplete
                                            outlined
                                            :items="empleados"
                                            item_text="nombre_completo"
                                            @dataMultiple="(data)=> empleadosSeleccionados = data"
                                            label="Empleados"
                                            ref="multEmpleados"
                                            :loading="loadingEmpleados"
                                        />
                                    </v-col>
                                    <v-col v-if="rol == 'root' || rol=='admin'  || rol=='admin-empresa'" cols="12" xs="12" sm="2" md="2" class="pb-0 ma-0">
                                        <div class="centrar">
                                            <v-checkbox
                                                hide-details
                                                class="shrink mr-4 mt-0 dias"
                                                :label="'Detalle por empleado'"
                                                v-model="detallePorEmpleado"
                                                @click="encabezadosEmpleadosSeleccionados = []">
                                            </v-checkbox>
                                        </div>
                                    </v-col>
                                    <v-col v-if="rol == 'root' || rol=='admin'  || rol=='admin-empresa'" xs="12" sm="3" md="3" class=" radio_group_01 radio-contrato" >
                                            <template >
                                                <div class="mb-4">Estatus de los empleados:</div>
                                            </template>
                                            <v-radio-group v-model="empleadoEstatus" row>
                                                <v-radio class="radio-label" label="Todos" value="ALL"></v-radio>
                                                <v-radio class="radio-label" label="Activos" value="ACTIVO"></v-radio>
                                                <v-radio class="radio-label" label="Inactivos" value="INACTIVO"></v-radio>
                                        </v-radio-group>
                                    </v-col>

                                   <v-col v-if="rol == 'root' || rol=='admin'  || rol=='admin-empresa'" cols="12" xs="12" sm="3" md="3" class="pb-0 ma-0">
                                        <div class="centrar">
                                            <v-checkbox
                                                hide-details
                                                class="shrink mr-4 mt-0 dias"
                                                :label="'Desglosar percepciones (Exentos y Gravados)'"
                                                v-model="desglosarPercepciones">
                                            </v-checkbox>
                                        </div>
                                    </v-col>

                                    <v-col v-if="(rol == 'root' || rol=='admin'  || rol=='admin-empresa') && desglosarPercepciones" xs="12" sm="3" md="3" class=" radio_group_01 radio-contrato" >
                                            <v-radio-group v-model="desglosarTipo" row>
                                                <v-radio class="radio-label" label="Con Total" value="conTotal"></v-radio>
                                                <v-radio class="radio-label" label="Sin Total" value="sinTotal"></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                    
                                    <v-col v-if="rol == 'root' || rol=='admin'  || rol=='admin-empresa'" cols="12" xs="12" sm="3" md="3" class="pb-0 ma-0">
                                        <div class="centrar">
                                            <v-checkbox
                                                hide-details
                                                class="shrink mr-4 mt-0 dias"
                                                :label="'Consolidar por empleado'"
                                                v-model="consolidadoEmpleado">
                                            </v-checkbox>
                                        </div>
                                    </v-col>
                                    <v-col v-if="rol == 'root' || rol=='admin'  || rol=='admin-empresa'" cols="12" xs="12" sm="3" md="3" class="pb-0 ma-0">
                                        <div class="centrar">
                                            <v-checkbox
                                                hide-details
                                                class="shrink mr-4 mt-0 dias"
                                                :label="'Costo social'"
                                                v-model="costoSocial">
                                            </v-checkbox>
                                        </div>
                                    </v-col>

                                    <v-col v-if="detallePorEmpleado" cols="12" xs="12" sm="12" md="12" class="pb-0 ma-0">
                                        <VuetifyMultipleAutocomplete
                                            outlined
                                            :items="encabezadosEmpleados"
                                            item_text="nombre"
                                            item_value="valor"
                                            :compress="false"
                                            @dataMultiple="(data)=> encabezadosEmpleadosSeleccionados = data"
                                            rules="required"
                                            label="Campos de los empleados"
                                            ref="multEmpleadosHeader"
                                        />
                                    </v-col>

                                    <v-col cols="12" xs="12" sm="4" md="4" class=" pb-0 ma-0">
                                        <v-autocomplete
                                            outlined
                                            label="Totales por"
                                            :items="totalizar_por"
                                            item-text="nombre"
                                            item-value="valor"
                                            persistent-hint
                                            v-model="totalizar_value"
                                            class="pa-0 ma-0"
                                        >
                                        </v-autocomplete>
                                    </v-col>

                                     <v-col v-if="rol == 'root' || rol=='admin' || rol=='admin-empresa'" cols="12" xs="12" sm="12" md="12" class="pb-0 ma-0 row">

                                     </v-col>
                                   
                                    <v-col v-if="rol == 'root' || rol=='admin' || rol=='admin-empresa'" cols="12" xs="12" sm="12" md="12" class="pb-0 ma-0">
                                    <v-divider class="divider"></v-divider>
                                    </v-col>

                                    
                                    <v-col v-if="rol == 'root' || rol=='admin' || rol=='admin-empresa'" cols="12" xs="12" sm="12" md="12" class="pb-0 ma-0 row">
                                       
                                           <div class="centrar">
                                        <v-checkbox
                                            
                                        
                                            hide-details
                                            class="shrink mr-4 mt-0 dias"
                                            :label="'Percepciones'"
                                            v-model="percepcionesBool"
                                        ></v-checkbox>
                                    </div>
                                     <div class="centrar">
                                        <v-checkbox
                                            
                                        
                                            hide-details
                                            class="shrink mr-4 mt-0 dias"
                                            :label="'Deducciones'"
                                            v-model="deduccionesBool"
                                        ></v-checkbox>
                                    </div>
                                     <div class="centrar">
                                        <v-checkbox
                                            
                                        
                                            hide-details
                                            class="shrink mr-4 mt-0 dias"
                                            :label="'Otros pagos'"
                                            v-model="otrosPagosBool"
                                        ></v-checkbox>
                                    </div>
                                    </v-col>
                                    <v-col v-if="rol == 'root' || rol=='admin' || rol=='admin-empresa'" cols="12" xs="12" sm="4" md="4" class="pb-0 ma-0">
                                        <VuetifyMultipleAutocomplete
                                            outlined
                                            :items="complementos"
                                            item_text="nombre"
                                            item_value="codigo"
                                            @dataMultiple="(data)=> complementosSeleccionados = data"
                                            label="Complementos"
                                            ref="multComplemento"
                                        />
                                    </v-col>
                                    <v-col v-if="rol == 'root' || rol=='admin' || rol=='admin-empresa'" cols="12" xs="12" sm="4" md="4" class="pb-0 ma-0">
                                        <div class="centrar">
                                            <v-checkbox
                                                hide-details
                                                class="shrink mr-4 mt-0 dias"
                                                :label="'Detalle de nómina'"
                                                v-model="detallePorNomina"
                                                @click="encabezadosNoiminasSeleccionados = []">
                                            </v-checkbox>
                                        </div>
                                    </v-col>
                                    <v-col v-if="detallePorNomina" cols="12" xs="12" sm="12" md="12" class="pb-0 ma-0">
                                        <VuetifyMultipleAutocomplete
                                            outlined
                                            :items="encabezadosNominas"
                                            item_text="nombre"
                                            item_value="valor"
                                            :compress="false"
                                            @dataMultiple="(data)=> encabezadosNominasSeleccionados = data"
                                            rules="required"
                                            label="Campos de la nómina"
                                            ref="multNominasHeader"
                                        />
                                    </v-col>
                                </v-row>
                            </ValidationObserver>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <template >
                <v-dialog v-model="dialogReporte" scrollable persistent width="700px" class="borde-card">
                    <v-card class="borde-card">
                            <v-card-title class="justify-center card_titulo">
                                <div class="headerModal">
                                    <img :src="imagen" class="imgModal" />
                                    <h2 class="titleModal">{{ titulo_modal }}</h2>
                                </div>
                            </v-card-title>
                            <ValidationObserver ref="form2" v-slot="{ invalid}">
                                <v-card-text class="card_texto">
                                    <v-container grid-list-md id="contenedor" class="pt-0">
                                        <v-divider class="divider"></v-divider>
                                            <v-col xs="12" sm="12" md="12" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Nombre del reporte" rules="required">
                                                    <v-text-field
                                                        outlined
                                                        id="nombre"
                                                        label="Nombre del reporte"
                                                        v-model="nombre_reporte"
                                                        :error-messages="errors" 
                                                        required
                                                    />
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col xs="12" sm="12" md="12" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="URL" rules="required">
                                                    <v-text-field
                                                        outlined
                                                        id="urlReporte"
                                                        label="URL del reporte"
                                                        v-model="urlReporte"
                                                        :error-messages="errors" 
                                                        required
                                                    />
                                                </ValidationProvider>
                                            </v-col>
                                    </v-container>
                                </v-card-text>
                                <v-card-actions class="d-flex justify-end card_accion">
                                    <button
                                        class="btnCerrar"
                                        @click="cerrarModal()" 
                                        :disabled="isSaving"
                                        
                                    >
                                        Cerrar
                                    </button>
                                    <v-btn
                                        class="btnGuardar"
                                    @click="guardarReporte()" 
                                    :loading="isSaving"
                                    :disabled="invalid"
                                    >
                                        Guardar
                                    </v-btn>
                                     <v-btn
                                        class="btnGuardar"
                                    @click="eliminarReporte()" 
                                    :disabled="reporte.cliente_id == null"
                                    >
                                        Eliminar
                                    </v-btn>
                                </v-card-actions>
                            </ValidationObserver>
                    </v-card>
                </v-dialog>
            </template>
        </v-container>
    </div>
</template>

<script>

//IMPORTS

//APIS

import Vue from 'vue';
import apiTipoContratoSAT from '@/api/nominas/tipoContrato';
import apiRegistroPatronal from "@/api/nominas/registroPatronal";
import apiTipoNomina from '@/api/nominas/tipoNomina';
import IncidenciasNominasApi from '@/api/nominas/incidencias';
import apiCalculoNomina from '@/api/nominas/calculoNomina';
import reportesDinamicosApi from '@/api/nominas/reporteDinamico';
import clientesApi from "@/api/clientes";
import empresaApi from "@/api/empresas";
import sucursalApi from "@/api/sucursales";
import departamentoApi from "@/api/departamentos";
import contratosApi from "@/api/contratos";
import documentoApi from "../api/documentos";
import apiEmpleados from '@/api/empleados';
import apiAnios from '@/api/anual_fiscal';

//COMPONENTS

import Datatable from '@/components/datatable/Datatable';
import VuetifyDatePicker from '@/components/VuetifyDatePicker.vue';
import ExpandableItem from '@/components/ExpandableItemPayments.vue';
import VuetifyMultipleAutocomplete from '@/components/VuetifyMultipleAutocomplete.vue';

//PLUGINS

import Notify from '@/plugins/notify';
import GetUrlImg from "@/plugins/Urlimagen";
import gFunctions from '@/js/global_functions';

export default {
    components: {
        VuetifyMultipleAutocomplete,
        VuetifyDatePicker,
        'data-table': Datatable,
        ExpandableItem,
    },
    data() {
        return {
            reportesDinamicos       : [],
            nombre_reporte          : "",
            accion                  : "create",
            datosLogin              : null,
            rol                     : null,
            cliente_value           : null,
            totalizar_value         : null,
            registroPatronal_value  : null,
            tipo_nomina_id          : null,

            dialog_pdf              : false,
            dialogSeleccionTipoPago : false,
            dialogReporte           : false,
            detallePorEmpleado      : false,
            consolidadoEmpleado     : false,
            costoSocial             : false,
            desglosarPercepciones   : false,
            detallePorNomina        : false,
            percepcionesBool        : false,
            deduccionesBool         : false,
            otrosPagosBool          : false,
            procesoFinalizado       : false,
            isSaving                : false,
            overlay                 : false,
            masivo                  : false,
            checkAll                : false,
            determinado             : false,

            reporte_dinamico_nombre: "Seleccionar tipo de reporte",
            titulo_modal    : "",
            url             : "contratos/find",
            imagen          : "/static/modal/contrato.png",
            fraseOverlay    : "Descargando",
            empleadoEstatus : "ALL",
            desglosarTipo   : "sinTotal",

            clientes                            : [],
            registrosPatronales                 : [],
            empresas                            : [],
            sucursales                          : [],
            departamentos                       : [],
            tipos_nominas                       : [],
            items_anios                         : [],
            nominas                             : [],
            empleados                           : [],
            complementos                        : [],
            estatusComplementos                 : [],
            encabezadosEmpleados                : [{nombre:"Clave",valor:"Clave-clave"},
                                                   {nombre:"Clave interna",valor:"Clave interna-clave_interna"},
                                                   {nombre:"Nombre",valor:"Nombre-nombre"},
                                                   {nombre:"Apellido paterno",valor:"Apellido paterno-apellido_paterno"},
                                                   {nombre:"Apellido materno",valor:"Apellido materno-apellido_materno"},
                                                   {nombre:"Nombre completo",valor:"Nombre completo-nombre_completo"},
                                                   {nombre:"Nacionalidad",valor:"Nacionalidad-nacionalidad"},
                                                   {nombre:"Fecha de nacimiento",valor:"Fecha de nacimiento-fecha_nacimiento"},
                                                   {nombre:"Lugar de nacimiento",valor:"Lugar de nacimiento-lugar_nacimiento"},
                                                   {nombre:"Sexo",valor:"Sexo-sexo"},
                                                   {nombre:"Estado civil",valor:"Estado civil-estado_civil_id"},
                                                   {nombre:"¿Tiene hijos?",valor:"¿Tiene hijos?-tiene_hijos"},
                                                   {nombre:"Código postal",valor:"Código postal-cp_id"},
                                                   {nombre:"Estado",valor:"Estado-estado_id"},
                                                   {nombre:"Municipio",valor:"Municipio-municipio_id"},
                                                   {nombre:"Dirección",valor:"Dirección-direccion"},
                                                   {nombre:"Colonia",valor:"Colonia-colonia_id"},
                                                   {nombre:"Teléfono",valor:"Teléfono-telefono"},
                                                   {nombre:"Célular",valor:"Célular-celular"},
                                                   {nombre:"Correo electrónico",valor:"Correo electrónico-correo"},
                                                   {nombre:"Nivel de estudios",valor:"Nivel de estudios-nivel_estudios_id"},
                                                   {nombre:"Conocimientos extra",valor:"Conocimientos extra-conocimientos_extras"},
                                                   {nombre:"Dirección laboral",valor:"Dirección laboral-direccion_laboral"},
                                                   {nombre:"No. IMSS",valor:"No. IMSS-imss"},
                                                   {nombre:"RFC",valor:"RFC-rfc"},
                                                   {nombre:"CURP",valor:"CURP-curp"},
                                                   {nombre:"Tipo de sangre",valor:"Tipo de sangre-tipo_sangre"},
                                                   {nombre:"Observaciones de salud",valor:"Observaciones de salud-observacion_salud"},
                                                   {nombre:"Cliente",valor:"Cliente-cliente_id"},
                                                   {nombre:"Empresa",valor:"Empresa-empresa_id"},
                                                   {nombre:"Sucursal",valor:"Sucursal-sucursal_id"},
                                                   {nombre:"Departamento",valor:"Departamento-departamento_id"},
                                                   {nombre:"Horario",valor:"Horario-horario_id"},                                                 
                                                   {nombre:"Puesto",valor:"Puesto-puesto_id"},
                                                   {nombre:"Tipo del empleado",valor:"Tipo-empleado_tipo_id"},
                                                   {nombre:"Días de vacaciones",valor:"Días de vacaciones-dias_vacaciones"},
                                                   {nombre:"Día de descanso",valor:"Día de descanso-dia_descanso"},
                                                   {nombre:"Entidad CFDI",valor:"Entidad CFDI-entidad_cfdi"},
                                                   {nombre:"Registro patronal",valor:"Registro patronal-registro_patronal_id"},
                                                   {nombre:"Fecha de ingreso",valor:"Fecha de ingreso-fecha_ingreso"},
                                                   {nombre:"Fecha de alta IMSS",valor:"Fecha de alta IMSS-fecha_alta_imss"},
                                                   {nombre:"Fecha de planta",valor:"Fecha de planta-fecha_planta"},
                                                   {nombre:"Fecha de reingreso",valor:"Fecha de reingreso-fecha_reingreso"},
                                                   {nombre:"Fecha de baja",valor:"Fecha de baja-fecha_baja"},
                                                   {nombre:"Fecha de cobro del finiquito",valor:"Fecha de cobro del finiquito-fecha_cobro_finiquito"},
                                                   {nombre:"Contrato",valor:"Contrato-tipo_contrato_id"},
                                                   {nombre:"Tipo de nómina",valor:"Tipo de nómina-tipo_nomina_id"},
                                                   {nombre:"Régimen fiscal",valor:"Régimen fiscal-tipo_regimen_sat_id"},
                                                   {nombre:"Prestaciones",valor:"Prestaciones-prestacion_id"},
                                                   {nombre:"Salario diario",valor:"Salario diario-salario_diario"},
                                                   {nombre:"Salario diario fiscal",valor:"Salario diario fiscal-salario_diario_fiscal"},
                                                   {nombre:"Salario base cotización",valor:"Salario base cotización-salario_base_cotizacion"},
                                                   {nombre:"Tipo de pago",valor:"Tipo de pago-tipo_pago"},
                                                   {nombre:"Cuenta INFONAVIT",valor:"Cuenta INFONAVIT-cuenta_infonavit"},
                                                   {nombre:"Tipo de descuento INFONAVIT",valor:"Tipo de descuento INFONAVIT-tipo_factor"},
                                                   {nombre:"Descuento INFONAVIT",valor:"Descuento INFONAVIT-factor_descuento"},
                                                   {nombre:"Fecha inicio del descuento INFONAVIT",valor:"Fecha inicio del descuento INFONAVIT-fecha_inicio_descuento"},
                                                   {nombre:"No. AFORE",valor:"No. AFORE-afore"},
                                                   {nombre:"Cuenta FONACOT",valor:"Cuenta FONACOT-cuenta_fonacot"},
                                                   {nombre:"Brigada",valor:"Brigada-brigada_proteccion_civil_id"},
                                                   {nombre:"Observaciones generales",valor:"Observaciones generales-observaciones"}
                                                   ],  
            encabezadosNominas                  : [{nombre:"Días de pago",valor:"Días de pago-dias_de_pago"},
                                                   {nombre:"Sueldo",valor:"Sueldo-sueldo-p_Sueldo"},
                                                   {nombre:"Subsidio para el empleo",valor:"Subsidio para el empleo-subsidio_entregar-o_SubsidioEntregar"},
                                                   {nombre:"Subsidio pagado",valor:"Subsidio pagado-subsidio_pagado_entregar"},
                                                   {nombre:"Subsidio causado",valor:"Subsidio causado-subsidio_causado-o_SubsidioCausado"},
                                                   {nombre:"Ajuste de subsidio causado",valor:"Ajuste de subsidio causado-ajuste_subsidio_causado-d_AjusteSubsidioCausado"},
                                                   {nombre:"Ajuste de subsidio pagado",valor:"Ajuste de subsidio pagado-ajuste_subsidio_pagado-d_AjusteSubsidioPagado"},
                                                   {nombre:"Total de otros ingresos",valor:"Total de otros ingresos-total_otros_ingresos"},
                                                   {nombre:"Percepciones exentas",valor:"Percepciones exentas-percepciones_excentas"},
                                                   {nombre:"Percepciones gravadas",valor:"Percepciones gravadas-percepciones_grabadas"},
                                                   {nombre:"Total de percepciones",valor:"Total de percepciones-total_percepciones"},
                                                   {nombre:"ISR",valor:"ISR-ISR-d_IsrRetener"},
                                                   {nombre:"ISR subsidio ajustado",valor:"ISR subsidio ajustado-ISR_ajustado_subsidio-o_IsrAjustadoSubsidio"},
                                                   {nombre:"Ajuste ISR",valor:"Ajuste ISR-ISR_ajuste_mensual"},
                                                   {nombre:"IMSS",valor:"IMSS-IMSS-d_Imss"},
                                                   {nombre:"Total de deducciones",valor:"Total de deducciones-total_deducciones"},
                                                   {nombre:"Neto a pagar",valor:"Neto a pagar-neto_pagar"},
                                                   {nombre:"Faltas",valor:"Faltas-faltas"},
                                                   {nombre:"Incapacidades",valor:"Incapacidades-incapacidades"},
                                                   {nombre:"Vacaciones",valor:"Vacaciones-vacaciones"},
                                                   {nombre:"Total INFONAVIT",valor:"Total INFONAVIT-total_infonavit_bimestral"},
                                                   ],
            totalizar_por                       : [{nombre:"Empresa",valor:"empresa"},
                                                   {nombre:"Sucursal",valor:"sucursal"},
                                                   {nombre:"Departamento",valor:"departamento"}
                                                   ],
            registrosPatronalesSeleccionados    : [],
            empresasSeleccionadas               : [],
            sucursalesSeleccionadas             : [],
            departamentosSeleccionados          : [],
            tiposNominasSeleccionadas           : [],
            aniosSeleccionados                  : [],
            anio_id                             : null,
            nominasSeleccionadas                : [],
            empleadosSeleccionados              : [],
            complementosSeleccionados           : [],
            estatusComplementosSeleccionados    : [],
            encabezadosEmpleadosSeleccionados   : [],
            encabezadosNominasSeleccionados     : [],
            columns                 : [
                {
                    label: "Tipo / Periodo",
                    name: "tipo",
                    filterable: true,
                },
                {
                    label: "Fecha de pago",
                    name: "fecha_pago",
                    filterable: true,
                },
                {
                    label: "Tipo de pago",
                    name: "tipo_pago",
                    filterable: true,
                },
                {
                    label: "Opciones",
                    name: "opciones",
                    align: 'center',
                    filterable: false,
                },
                {
                    label: "",
                    name: "desplegar",
                    align: 'center',
                    width:"8",
                    filterable: false,
                },
            ],

            filters         : {
            },
            pagoNomina      : {
                tipo_pago : "1"
            },
            archivo         : {
                archivo                 : [],
                cliente_id              : null,
                empresa_id              : null,
                sucursal_id             : null,
                departamento_id         : null,
                empleado_id             : null,
                documento_id            : null,
                url                     : null,
                extension               : null,
                nombre                  : null,
                contrato_empleado_id    : null
            },
            reporte           : {
                id : null,
                cliente_id : null
            },
            urlReporte: '',
            loadingEmpresa: false,
            loadingSucursales: false,
            loadingDepartamentos: false,
            loadingTiposNomina: false,
            loadingNominas: false,
            loadingEmpleados: false,
            mesesSeleccionados:[],
            meses: [
                {
                    nombre:"Enero",
                    valor: "Enero"
                },
                {
                    nombre:"Febrero",
                    valor: "Febrero"
                },
                {
                    nombre:"Marzo",
                    valor: "Marzo"
                },
                {
                    nombre:"Abril",
                    valor: "Abril"
                },
                {
                    nombre:"Mayo",
                    valor: "Mayo"
                },
                {
                    nombre:"Junio",
                    valor: "Junio"
                },
                {
                    nombre:"Julio",
                    valor: "Julio"
                },
                {
                    nombre:"Agosto",
                    valor: "Agosto"
                },
                {
                    nombre:"Septiembre",
                    valor: "Septiembre"
                },
                {
                    nombre:"Octubre",
                    valor: "Octubre"
                },
                {
                    nombre:"Noviembre",
                    valor: "Noviembre"
                },
                {
                    nombre:"Diciembre",
                    valor: "Diciembre"
                },

            ]
        }
    },
    /* WATCH 
    Entrada: valor nuevo de la variable y valor pasado de la variable (newValue,oldValue)
    Salida:
    Descripcion: Las funciones de este tipo reciben el valor 
                 nuevo y pasado de una variable cuando detecta 
                 un cambio para posteriormente ejecutar alguna 
                 instruccion.*/
    watch: {
        /*Descripcion: Ejectuta cambiaCliente() mandando
                       el nuevo valor de esta variable*/
        cliente_value: function(val) {
            this.cambiaCliente(val);
            this.getReportesDinamicos();
        },
        /*Descripcion: Ejectuta getcliente() */
        registroPatronal_value: function(val) {
            this.getEmpleados();
        },
        registrosPatronalesSeleccionados:{
            deep:true,  
            handler(val){this.getEmpleados();}
        },
        /*Descripcion: Ejectuta cambiaEmpresa() mandando
                       el nuevo valor de esta variable*/
        empresasSeleccionadas:{
            deep:true,  
            handler(val){
                this.cambiaEmpresas(val);
                this.getTiposNominas();
                this.getAnios();
            }
        },
        /*Descripcion: Ejectuta cambiaScursal() mandando
                       el nuevo valor de esta variable*/
        sucursalesSeleccionadas:{
            deep:true,  
            handler(val){this.cambiaSucursales(val);}
        },

        /*Descripcion: Ejectuta getEmpleados() */
        departamentosSeleccionados:{
            deep:true,  
            handler(val){this.getEmpleados();}
        },
        
        /*Descripcion: Ejectuta cambiaTipoNominas() */
        tiposNominasSeleccionadas:{
            deep:true,  
            handler(val){

                if(this.anio_id){
                    this.cambiaTipoNominas(val);
                }
                
            }
        },

        mesesSeleccionados:{
            deep: true,
            handler(val) {
                if(this.anio_id){
                    this.cambiaTipoNominas(this.tiposNominasSeleccionadas);
                }
            }
        },

        anio_id: function(val, oldVal){
            if(val){
                if(this.tiposNominasSeleccionadas.length>0){
                    this.cambiaTipoNominas(this.tiposNominasSeleccionadas)
                }
            }
        },

        empleadoEstatus: function(val){
            if(this.cliente_value != null)
                this.getEmpleados();
        },
        percepcionesBool:function(val){
            this.changeComplemento();
        },
        deduccionesBool:function(val){
            this.changeComplemento();
        },
        otrosPagosBool:function(val){
            this.changeComplemento();
        }
    },
    methods: {
        /**
         * @method getEmpleados Este método realiza petición a la API de empleados relacionados con la capacitación.
         * @description Realiza petición a la API de empleados cuando la empresa es seleccionada y el resultado lo almacena en el array empleados[].
         * Si la petición no se realiza se manda un mensaje al usuario indicandole que no se pudo realizar la petición.
        */
        getEmpleados(){
            let parametros = { 
                paginate: false,
                usuario_id : this.datosLogin.id,
                tipo_empleado: 'ambos',
                cliente_id: this.cliente_value, 
                rol_name: this.datosLogin.rol.name,
                arrayRegistroPatronal : this.registrosPatronalesSeleccionados.length == 0 ? null:JSON.stringify(this.registrosPatronalesSeleccionados), 
                arrayEmpresa : this.empresasSeleccionadas.length == 0 ? null:JSON.stringify(this.empresasSeleccionadas), 
                arraySucursal : this.sucursalesSeleccionadas.length == 0 ? null:JSON.stringify(this.sucursalesSeleccionadas), 
                arrayDepartamento : this.departamentosSeleccionados.length == 0 ? null:JSON.stringify(this.departamentosSeleccionados)
            };

            if(this.empleadoEstatus == "ALL"){
                parametros.all = true;
            }else if(this.empleadoEstatus == "ACTIVO"){
                parametros.activo = true;
                parametros.baja   = false;
            }else if(this.empleadoEstatus == "INACTIVO"){
                parametros.activo = false;
                parametros.baja   = true;
            }
            this.loadingEmpleados = true
            apiEmpleados.getEmpleados(parametros).then((response)=>{
                this.loadingEmpleados = false
                this.empleados = response.data;
                this.empleados.map(emp => emp.nombre_completo += " - "+emp.nombre_empresa + " - "+emp.nombre_sucursal +(emp.nombre_departamento != null ?'-':'') + (emp.nombre_departamento != null ?emp.nombre_departamento:'') );
            })
            .catch(error => {
                this.loadingEmpleados = false
                 Notify.ErrorToast("No se pudo realizar la petición de empleados.");
            });
        },

        //FUNCIONES GENERALES

        /*
        Entrada:
        Salida:
        Descripción: Se ejectua desde el crate para poder ver 
                     el tipo del rol del usuario loguado y en base a eso 
                     realizar validaciones por rol ademas de ejecutar las 
                     funcinones para el funcionamiento inicial del modulo.
        */
        init() {
            this.datosLogin = this.$session.get("usuario");
            this.rol = this.datosLogin.rol.name;
            
            this.urlImg = GetUrlImg.getURL();

            if (this.rol == "root") {
                this.getClientes();
                this.getReportesDinamicos();
            }
 
            if (this.rol == "admin") {
                this.cliente_value = this.datosLogin.cliente_id;
            }

            if (this.rol == "admin-empresa") {
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresasSeleccionadas.push(this.datosLogin.empresa_id);
            }

            if(this.rol == "admin-sucursal"){
                Notify.ErrorToast("Tu rol no tiene acceso a este módulo");
            }

            //this.getTiposContratosSAT();
        },

        /*
        Entrada: isSelected: Boleano pasando el valor del check todos de la tabla.
                 items: Array de ids de empleados para llenar los checbox seleccionado
        Salida:
        Descripción: Se asigna eñ valor del check masivo para validar en
                     este modulo y nos da los items de los empleados si 
                     o no seleccionados.
        */
        selectAll(isSelected, items) {
            this.checkAll = isSelected;
            this.selected_general = items;

            if(!isSelected){
                this.unselected_general = [];
            }
        },

        /*
        Entrada: id: id del empledo seleccionado
        Salida:
        Descripción: Agrega o quita del array de empleados seleccionados
                     segun el id mandado y agrega a los no seeccionado en el 
                     caso del check masivo sea verdadero.
        */
        selectEmployee(id){
            if(this.selected_general.includes(id)){
                let index = this.selected_general.indexOf(id);
                this.selected_general.splice(index, 1);
            }else{
               this.selected_general.push(id); 
            }

            if(this.checkAll){
                if(this.unselected_general.includes(id)){
                    let index = this.unselected_general.indexOf(id);
                    this.unselected_general.splice(index, 1);
                }else{
                    this.unselected_general.push(id); 
                }
            }

            //console.log("sel",this.selected_general)
            //console.log("unsel",this.unselected_general)
        },
        
        /*
        Entrada:
        Salida:
        Descripción: Ejectuta API get de tipos contratos sat para 
                     llenar el select de tipo contrato.
        */
        getTiposContratosSAT(){
            let parametros = { activo:true, paginate:false, order:"asc" }
            apiTipoContratoSAT.find(parametros).then((response) => {
                let format = response.data;
                if(response.data.length != 0){
                    format.forEach( item => {
                        item.claveDescripcion = item.clave + ' - ' + item.descripcion
                        this.tipoContratos.push(item);
                    })
                }
            })
            .catch(err => {
                console.log(err);
                Notify.ErrorToast("Ocurrió un error al cargar los Tipos de contratos.");
            });
        },

        async getRegistroPatronal(){
            let parametros = { activo:true, paginate:false, order:"asc", cliente_id: this.cliente_value }
            await apiRegistroPatronal.find(parametros).then((response) => {
                this.registroPatronal = response.data;
            })
            .catch(err => {
                console.log(err);
                Notify.ErrorToast("Ocurrió un error al cargar los Registros Patronales.");
            });
        },

        async getTiposNominas() {
            let param = {
                activo      : true,
                paginate    : false,
                cliente_id  : this.cliente_value,
                arrayEmpresa: JSON.stringify(this.empresasSeleccionadas)
            }
            this.tipos_nominas = [];
            this.loadingNominas = true
            await apiTipoNomina.find(param).then((response) => {
                this.loadingNominas = false
                this.tipos_nominas = response.data;
                this.tipos_nominas.map(tipo_nomina => tipo_nomina.claveDescripcion = tipo_nomina.clave + ' - ' + tipo_nomina.descripcion+ " - "+tipo_nomina.nombre_empresa);
            })
            .catch (err => {
                this.loadingNominas = false
                console.log(err);
                Notify.ErrorToast("No se pudieron cargar los tipos de nómina");
            })
        },
        async getAnios() {
            let param = {
                activo      : true,
                paginate    : false,
                cliente_id  : this.cliente_value,
                arrayEmpresa: JSON.stringify(this.empresasSeleccionadas)
            }
            this.items_anios = [];
            await apiAnios.empresasAnios(param).then((response) => {
                this.items_anios = response.data;
            })
            .catch (err => {
                console.log(err);
                Notify.ErrorToast("No se pudieron cargar los tipos de nómina");
            })
        },
        
       /* async getPeriodos(tipoNomID){
            let param = {
                activo      : true,
                paginate    : false,
                acumulado :true,
                tipo_nomina_id : tipoNomID
            }
            await apiPeriodos.find(param).then((response) => {
                this.periodos = response.data;
            })
            .catch (err => {
                console.log(err);
                Notify.ErrorToast("No se pudieron cargar los periodos");
            })
        },

        async getNominas(items){
            
        },*/

        /*
        Entrada:
        Salida:
        Descripción: Ejectuta API get de clientes para llenar 
                     el select en el filtrado.
        */
        async getClientes() {
        let param = { activo: true, paginate: false };
        await clientesApi
            .getClientes(param)
            .then(response => {
                this.clientes = response.data;
            })
            .catch(err => {
            console.error(err);
            Notify.ErrorToast("Ocurrió un error al cargar los clientes");
            });
        },

        /*
        Entrada:
        Salida:
        Descripción: Ejectuta API get de reportes dinamicos para llenar 
                     el select en el filtrado.
        */
        async getReportesDinamicos() {
        let param = {all:true,cliente_id:this.cliente_value};

        await reportesDinamicosApi
            .find(param)
            .then(response => {
                this.reportesDinamicos = response.data;
            })
            .catch(err => {
            console.error(err);
            Notify.ErrorToast("Ocurrió un error al cargar los reportes");
            });
        },

        /*
        Entrada:
        Salida:
        Descripción: Ejectuta API get de Empresas para llenar 
                     el select en el filtrado.
        */
        async cambiaCliente(cliente) {
            if(cliente == null){
                return;
            }

            let param = { activo: true, paginate: false, cliente_id: cliente };
            this.loadingEmpresa = true
            await empresaApi
                    .getEmpresas(param)
                    .then(response => {
                        this.loadingEmpresa = false
                        this.empresas = response.data;
                        this.getEmpleados();
                })
                .catch(err => {
                    this.loadingEmpresa = false
                    console.error(err);
                    Notify.ErrorToast("Ocurrió un error al cargar las empresas.");
                });
            this.registroPatronal_value = null;
            this.registrosPatronales = []
            await apiRegistroPatronal
                    .find(param)
                    .then(response => {
                        //this.registrosPatronales = response.data;
                        let format = response.data;
                        if(response.data.length != 0){
                            format.forEach( item => {
                                item.nombreClave = item.nombre + ' - ' + item.clave
                                this.registrosPatronales.push(item);
                            })
                        }
                })
                .catch(err => {
                    console.error(err);
                    Notify.ErrorToast("Ocurrió un error al cargar las empresas.");
                });
            this.changeComplemento();
        },

        /*
        Entrada:
        Salida:
        Descripción: Ejectuta API IncidenciasNominasApi.getTiposIncidencias para llenar 
                     el select de complementos.
        */
        async getComplementos() {
            let params = {
                cliente_id : this.cliente_value
            };

            await IncidenciasNominasApi.getTiposIncidencias(params)
                .then(response => {
                    this.complementos = response.data;
                    this.complementos.map(com => com.nombre = com.codigo+" - "+com.nombre);
                })
                .catch(err => {
                    console.error(err);
                    Notify.ErrorToast("Ocurrió un error al cargar los complementos de nómina.");
                });
        },

        /*
        Entrada:
        Salida:
        Descripción: Manupula array de complementos según los checks
                     de complementos seleccionados.
        */
        async changeComplemento(){
            if(this.cliente_value != null){
                await this.getComplementos();
                if(this.percepcionesBool || this.deduccionesBool || this.otrosPagosBool){
                    
                    let percepciones = [];
                    let deducciones = [];
                    let otrosPagos = [];

                    for(let item of this.complementos){
                        switch (item.codigo.charAt(0)) {
                            case 'P':
                                percepciones.push(item);
                                break;
                            case 'D':
                                deducciones.push(item);
                                break;
                            case 'O':
                                otrosPagos.push(item);
                                break;    
                            default:
                                break;
                        }
                    }

                    this.complementos = [];
                    this.complementosSeleccionados = [];
                    if(this.percepcionesBool){
                        this.complementos = [...this.complementos,...percepciones];
                    }
                    if(this.deduccionesBool){
                        this.complementos = [...this.complementos,...deducciones];
                    }
                    if(this.otrosPagosBool){
                        this.complementos = [...this.complementos,...otrosPagos];
                    }
                }
            }
        },

         /*
        Entrada:
        Salida:
        Descripción: Ejectuta API get de Sucursales para llenar
                     el select en el filtrado.
        */
        async cambiaEmpresas(items) {
            if(items.length == 0){
                this.sucursales = [];
                return;
            }
            let parametros = { activo: true, paginate: false, arrayEmpresa: JSON.stringify(items)};
            this.loadingSucursales = true
            await sucursalApi
                .getSucursales(parametros)
                .then(response => {
                    this.loadingSucursales = false
                    this.sucursales = response.data;
                    this.sucursales.map(suc => suc.nombre += " - "+suc.nombre_empresa);
                    this.getEmpleados();
                })
                .catch(err => {
                    this.loadingSucursales = false
                    console.error(err);
                    Notify.ErrorToast("Ocurrió un error al cargar las sucursales.");
                });
        },

        /*
        Entrada:
        Salida:
        Descripción: Ejectuta API get de Sucursales para llenar
                     el select en el filtrado.
        */
        cambiaSucursales(items) {
            if(items.length == 0){
                this.departamentos = [];
                return;
            }
            
            let parametros = { activo: true, paginate: false, arraySucursal: JSON.stringify(items) };
            this.loadingDepartamentos = true
            departamentoApi.getDepartamentos(parametros).then((response)=>{
                this.loadingDepartamentos = false
                this.departamentos = response.data;
                this.departamentos.map(depto => depto.nombre += " - "+depto.nombre_sucursal);
                this.getEmpleados();
            })
            .catch(error => {
                this.loadingDepartamentos = false
                this.resetValues();
                Notify.ErrorToast(error);
            });
        },

         /*
        Entrada:
        Salida:
        Descripción: Ejectuta API find de calculo de nomina para llenar
                     el select en el filtrado.
        */
        cambiaTipoNominas(items=[]) {
            this.nominas = [];
            if(items.length == 0){
                return;
            }
            
            let param = {
                cliente_id      : this.cliente_value,
                acumulado       : true,
                paginate        : false,
                arrayTipoNomina : JSON.stringify(items),
                anioFiscal_id   : this.anio_id,
                mesAcumulacion  : this.mesesSeleccionados.length!=0 ? JSON.stringify(this.mesesSeleccionados): null
            }
            this.loadingTiposNomina = true
            apiCalculoNomina.find(param).then((response) => {
                this.loadingTiposNomina = false
                this.nominas = response.data;
                this.nominas.map(nomina => nomina.nombre = nomina.clave_tipo_nomina + ' - ' + nomina.descripcion+ " - Periodo "+nomina.numero_periodo);
            
            })
            .catch (err => {
                this.loadingTiposNomina = false
                console.log(err);
                Notify.ErrorToast("No se pudieron encontrar las nóminas.");
            })
        },

        /*
        Entrada:err Array de string
        Salida:
        Descripción: Recibe un array de errores de los apis para 
                     ser mostrados con un toast.
        */
        respuesta_error(err) {
            let error = Object.values(err.response.data.error);
            let leyenda = ``;
            for (var i = 0; i < error.length; i++) {
                leyenda += `* ` + error[i] + `\n`;
            }

            Notify.ErrorToast(leyenda);
        },

        /*
        Entrada:
        Salida:
        Descripción: Prepara los parametros para el filtrado 
                     de la tabla segun se llenen los inputs 
                     en la bisqueda avanzada.
        */
        setFilters(){
            let nombre          = this.nombre;
            let cliente         = this.cliente_value;
            let empresa         = this.empresa_value;
            let sucursal        = this.sucursal_value;
            let tipo_contrato   = this.tipo_contrato_value;
            let estatus          = this.contrato_estatus_value; 
            

            if (this.rol == "admin") {
                cliente = this.datosLogin.cliente_id;
            } else if (this.rol == "admin-empresa") {
                empresa = this.datosLogin.empresa_id;
            } else if (this.rol == "admin-sucursal") {
                sucursal = this.datosLogin.sucursal_id;
            }

            let filterParams = {
                nombre              : nombre,
                cliente_id          : cliente,
                empresa_id          : empresa,
                sucursal_id         : sucursal,
                tipo_contrato_id    : tipo_contrato,
                estatus_id          : estatus
            };

            let defaultParams = {
                activo: true,
                paginate: true,
                include_padres: true,
                rol_name: this.datosLogin.rol.name,
                usuario_id: this.datosLogin.id
            };

            if (filterParams != null) {
                Object.assign(defaultParams, filterParams);
            }

            this.filters = defaultParams;

            this.resetValues();
        },

        /*
        Entrada:
        Salida:
        Descripción: Resetea las variables para cerrar un 
                     modal y dejarlo en estado inicial.
        */
        openModalSave() {
            if(this.accion == "create")
                this.titulo_modal = "Guardar nuevo reporte"
            else
                this.titulo_modal = "Actualizar reporte"
            this.dialogReporte = true;
        },
        
        /*
        Entrada:
        Salida:
        Descripción: Resetea las variables para cerrar un 
                     modal y dejarlo en estado inicial.
        */
        cerrarModal() {
            this.isSaving = false;
            this.dialogReporte = false;
        },

        /*
        Entrada:
        Salida:
        Descripción: Resetea las variables del modulo 
                     segun el rol para dejarlo en estado inicial.
        */
        resetValues() {

            this.$nextTick(() => { 

                if (this.rol == "root") {
                    this.cliente_value           = null;
                    if(this.$refs.multRegistroPatronal !== undefined)
                        this.$refs.multRegistroPatronal.setData([]);
                    if(this.$refs.multEmpresa !== undefined)
                        this.$refs.multEmpresa.setData([]);
                    if(this.$refs.multSucursal !== undefined)
                        this.$refs.multSucursal.setData([]);
                    this.registrosPatronales = []
                    this.empresas = []
                    this.empleados = []
                }
    
                if (this.rol == "admin") {
                    if(this.$refs.multRegistroPatronal !== undefined)
                        this.$refs.multRegistroPatronal.setData([]);
                    if(this.$refs.multEmpresa !== undefined)
                        this.$refs.multEmpresa.setData([]);
                    if(this.$refs.multSucursal !== undefined)
                        this.$refs.multSucursal.setData([]);
                }

                if (this.rol == "admin-empresa") {
                    if(this.$refs.multSucursal !== undefined)
                        this.$refs.multSucursal.setData([]);
                } 

                this.reporte_dinamico_nombre = "Seleccionar tipo de reporte";

                if(this.$refs.multDepartamento !== undefined)
                        this.$refs.multDepartamento.setData([]);
                if(this.$refs.multTiposNomina !== undefined)
                    this.$refs.multTiposNomina.setData([]);
                this.anio_id = null;
                if(this.$refs.multNominas !== undefined)
                    this.$refs.multNominas.setData([]);
                if(this.$refs.multEmpleado !== undefined)
                    this.$refs.multEmpleado.setData([]);
                if(this.$refs.multNominasHeader !== undefined)
                    this.$refs.multNominasHeader.setData([]);
                if(this.$refs.multEmpleadosHeader !== undefined)
                    this.$refs.multEmpleadosHeader.setData([]);
                this.$refs.multComplemento.setData([]);

                if(this.$refs.multMeses !== undefined){
                    this.$refs.multMeses.setData([]);
                }

                this.accion             = "create";
                this.reporte.id         = null;
                this.reporte.cliente_id = null;

                this.detallePorEmpleado = false;
                this.totalizar_value    = null;
                this.consolidadoEmpleado = false;
                this.costoSocial        = false;
                this.empleadoEstatus    = "ALL";
                this.percepcionesBool   = false;
                this.deduccionesBool    = false;
                this.otrosPagosBool     = false;
                this.detallePorNomina   = false;
            });
        },

        changeReporteDinamicoItem(data){
            this.accion = "update";
            this.reporte.id              = data.id;
            this.reporte.cliente_id      = data.cliente_id;
            this.reporte_dinamico_nombre = data.nombre;
            this.nombre_reporte          = data.nombre;
            //this.cliente_value           = data.cliente_id;
            this.urlReporte              = data.url;

            this.detallePorEmpleado = data.detalle_empleado;
            this.detallePorNomina   = data.detalle_nomina;
            this.percepcionesBool   = data.percepciones;
            this.deduccionesBool    = data.deducciones;
            this.otrosPagosBool     = data.otros_pagos; 
            this.empleadoEstatus    = data.estatus_empleado;
             
            this.$nextTick(()=>{
                if(this.$refs.multNominasHeader !== undefined)
                    this.$refs.multNominasHeader.setData(JSON.parse(data.campos_nominas));
                if(this.$refs.multEmpleadosHeader !== undefined)
                    this.$refs.multEmpleadosHeader.setData(JSON.parse(data.campos_empleados));
                setTimeout(()=>{this.$refs.multComplemento.setData(JSON.parse(data.complementos))},1000);
            });

        },

        //FUNCIONES ESPECIFICAS

        guardarReporte(){
            //funcion para guardar o actulizar un contrato o variante
            let reporte   = null;

            this.isSaving = true;

            let parametros = {
                id                    : this.reporte.id,
                cliente_id            : this.cliente_value,
                nombre                : this.nombre_reporte,
                url                   : this.urlReporte,
                campos_empleados      : this.empleadosSeleccionados,
                tipo_nomina_id        : this.tipo_nomina_id,
                detalle_empleado      : this.detallePorEmpleado,
                consolidado_empleado  : this.consolidadoEmpleado,
                costo_social          : this.costoSocial,
                estatus_empleado      : this.empleadoEstatus,
                detalle_nomina        : this.detallePorNomina,
                percepciones          : this.percepcionesBool,
                deducciones           : this.deduccionesBool,
                otros_pagos           : this.otrosPagosBool,
                campos_empleados      : this.encabezadosEmpleadosSeleccionados,
                campos_nominas        : this.encabezadosNominasSeleccionados,
                arrayComplemento      : this.complementosSeleccionados
            }
            
            if(this.accion == "create")
                reporte = reportesDinamicosApi.store(parametros);
            else
                reporte = reportesDinamicosApi.update(parametros);

            reporte.then(async (response) => {
                //console.log("repuesta",response);
                this.isSaving = false;
                this.getReportesDinamicos();
                this.cerrarModal();

                Notify.Success("Operación exitosa", "El reporte se guardó satisfactoriamente.");
            })
            .catch(err => {
                this.isSaving = false;
                gFunctions.errorParse(err.response.data.error);
            });
        },

        eliminarReporte(id = null){

            if(!id){
                id = this.reporte.id
            }
            Notify.Alert(
                "¡Hey! ¿Estás seguro?",
                "¿Estás seguro que deseas eliminar este reporte " + this.nombre_reporte + "?",
                "SI! QUIERO ELIMINARLO",
                () => {
                    reportesDinamicosApi.delete(id).then(response => {
                        this.resetValues();
                        this.getReportesDinamicos();
                        this.cerrarModal();
                        Notify.Success("Operación exitosa", "El reporte se elimino satisfactoriamente.");
                    })
                    .catch(err => {
                        console.error(err);
                        Notify.ErrorToast(err.response.data.error);
                    });
                }
            )
        },

        /*
        Entrada:
        Salida:
        Descripción: Ejecuta el api contratos/descargar para traer el 
                     fromato del contrato para poder descargarlo 
                     y frimarlo fisicamente.
        */
        async generarReporte(type) {
            this.$refs.form.validate().then(success => {
				if(!success) {
                    return;
                }
				if (success) {
					this.overlay = true;
                    let self = this;
                    const FileDownload = require("js-file-download");
                    let url = "reportes-dinamicos/generar";
                    var axios = require('axios');
                    var today = new Date();
                    let parametros = {nombre                : "Reporte",
                                    tipo                  : type,
                                    cliente_id            : this.cliente_value,
                                    arrayRegistroPatronal : this.registrosPatronalesSeleccionados,
                                    arrayEmpresa          : this.empresasSeleccionadas,
                                    arraySucursal         : this.sucursalesSeleccionadas,
                                    arrayDepartamento     : this.departamentosSeleccionados,
                                    arrayEmpleado         : this.empleadosSeleccionados,
                                    arrayTipoNomina       : this.tiposNominasSeleccionadas,
                                    arrayNomina           : this.nominasSeleccionadas,
                                    detalle_empleado      : this.detallePorEmpleado,
                                    consolidado_empleado  : this.consolidadoEmpleado,
                                    costo_social          : this.costoSocial,
                                    desglosar_percepciones: this.desglosarPercepciones,
                                    desglosar_tipo        : this.desglosarTipo,
                                    arrayEncabezado       : this.encabezadosEmpleadosSeleccionados,
                                    arrayEncabezadoNomina : this.encabezadosNominasSeleccionados,
                                    arrayComplemento      : this.complementosSeleccionados,
                                    percepciones          : this.percepcionesBool,
                                    deducciones           : this.deduccionesBool,
                                    otros_pagos           : this.otrosPagosBool,
                                    totalizar             : this.totalizar_value,
                                    anioFiscal_id         : this.anio_id,
                                    mesAcumulacion        : this.mesesSeleccionados  
                                    }

                    if(this.empleadoEstatus == "ALL"){
                        parametros.all = true;
                    }else if(this.empleadoEstatus == "ACTIVO"){
                        parametros.activo = true;
                        parametros.baja   = false;
                    }else if(this.empleadoEstatus == "INACTIVO"){
                        parametros.activo = false;
                        parametros.baja   = true;
                    }

                    var nombre =  "Reporte"+today.getDate() +
                                    "_" +
                                    (today.getMonth() + 1) +
                                    "_" +
                                    today.getFullYear() +
                                    "_" +
                                    today.getHours() +
                                    "," +
                                    today.getMinutes() +
                                    "," +
                                    today.getSeconds() +"." + type;

                    try {
                        axios({
                            method: "post",
                            url: url,
                            responseType: "blob",
                            data: parametros,
                        }).then(function(response) {
                            self.overlay = false;
                            if (response.status == 200) 
                                FileDownload(response.data,nombre);
                            else 
                                Notify.ErrorToast("Algo ha salido mal, intenta de nuevo o consulta a soporte técnico.");
                        });
                    } catch (error) {
                        self.overlay = false;
                    }
				}
			});
        },

    },
    //CREATED Se ejecutan las funciones o intrucciones iniciales para el funcionamiento del modulo.
    created() {
        this.init();
    },
    mounted(){
        
    },
    updated() {        
        /*document.querySelector('#tableScroll thead tr th:nth-child(1)').style.paddingRight   = "4px";
        document.querySelector('#tableScroll thead tr th:nth-child(1)').style.width   = "50px";
        document.querySelector('#tableScroll thead tr th:nth-child(6)').style.paddingLeft   = "8px";
        document.querySelector('#tableScroll thead tr th:nth-child(6)').style.width   = "80px";*/
    }
    
}
</script>


<style scoped>
    #padre {
        padding-left: 40%;
        height: 200px;
    }
    #loading {
        display: table-cell;
        vertical-align: middle;
    }
    #contenedor {
        margin-top: -15px;
    }
    .invertirColor {
        color: white;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: rgba(0,0,0,0.54);
    }
    .tamIcono {
        height: 24px !important;
        align-self: center !important;
    }
    .iconoDelgado {
        color: white;
        -webkit-text-stroke-width: 1px;
        -webkit-text-fill-color: rgba(0,0,0,0.54);
    }
    .botonModal.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
        display: none;
    }
    .cursor-pointer{
      cursor:pointer;
    }
    .radio_group_01 div{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: #828282;
    }

    iframe{
        border:none;
    }
    .btnReportes.theme--light.v-btn {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #828282;
        text-transform: none;
        transition: 0.3s;
        height: 38px !important;
        background: #EFEFEF;
        border-radius: 15px;
    }

    .btnReportes.theme--light.v-btn:hover {
        background: #EFEFEF !important;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08) !important;
        -webkit-box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08) !important; 
        -moz-box-shadow:  0px 4px 12px rgba(0, 0, 0, 0.08) !important;
    }

    .p-title{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 18px;
        letter-spacing: 0.1px;
        color: #444975;
    }
    .btn-agrupar-por {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #FFFFFF;
        text-transform: none;
        transition: 0.3s;
        height: 38px !important;
        background: #58C5D8;
        border-radius: 50px;
    }

    .btnGenerar {
        font-family: "Montserrat" !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 18px !important;
        background-color: #2DB9D1 !important;
        border-radius: 80px !important;
        color: #FFFFFF !important;
        outline: none;
        text-transform: none !important;
        transition: all .2s;
        padding: 10px 23px;
    }
    .margen {
		height: 48px;
		margin-bottom: 20px;
		margin-left: 1px;
	}
    .text-exportar{
        font-family: 'Montserrat';  
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        display: flex;
        letter-spacing: 0.1px;
        color: #828282;
    }
</style>
