import Vue from 'vue';

export default{
    getDocumentos(parametros){
        return Vue.axios.get("documentos/find", {params: parametros}).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    getDocumento(id){
        return Vue.axios.get("documentos/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    updateDocumento(parametros){
        return Vue.axios.put("documentos", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    deleteDocumento(id){
        return Vue.axios.delete("documentos/"+ id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    addDocumento(parametros){
        return Vue.axios.post("documentos", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },

    //Direcion para guardar el documento(archivo)
    addArchivo(parametros){
        return Vue.axios.post("empleados/upload-documento", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    updateArchivo(parametros){
        return Vue.axios.post("empleados/update-documento", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },

    getDocumentosFaltantes(parametros){
        return Vue.axios.get("documentos/faltantes", {params: parametros}).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
}